import * as React from 'react'
import styled, { css } from 'styled-components'

import { Handler } from '../../helpers/typeHelper'
import { darkRoyalBlue, royalBlue } from '../../theme/colors'
import { Text, TextProps } from '../Text/Text'

export interface ExternalLinkProps {
  variant?: TextProps['variant']
  href?: string
  children: React.ReactNode
  styles?: string
  color?: string
  className?: string
  target?: React.HTMLAttributeAnchorTarget
  onClick?: Handler
}

export const Link = styled.a<{ $color?: string }>`
  text-decoration: none;
  color: ${royalBlue};

  &:hover {
    color: ${darkRoyalBlue};
  }

  ${(p) =>
    p.$color &&
    css`
      color: ${p.$color};

      &:hover {
        color: ${p.$color};
      }
    `}
`

export function ExternalLink({
  href,
  variant,
  children,
  styles,
  color = '',
  className,
  onClick,
  target = '_blank',
}: ExternalLinkProps) {
  return (
    <Link href={href} target={target} $color={color} className={className} onClick={onClick}>
      <Text variant={variant || 'header4'} fontColor="inherit" as="span" styles={styles}>
        {children}
      </Text>
    </Link>
  )
}
