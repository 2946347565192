import _ from 'lodash'
import { DateTime } from 'luxon'
import { GenericLoadStopProgress, Job } from 'shared/models/job'
import {
  LoadStop,
  LoadStopProgressStatus,
  LoadStopType,
  LocationVector,
  TrackingStatus,
} from 'shared/models/load'

import { EquipmentType } from '../models/equipment'
import { getTimeDifference, getTimeDifferenceFromNowString, replaceUnderscore } from './formatters'

export const getJobTrackingStatusToDisplay = <T extends GenericLoadStopProgress>(
  trackingStatus: TrackingStatus,
  loadStopProgress: T | undefined,
  showHosEta: boolean,
  isBrokerView: boolean,
  latestTruckLocationVector?: LocationVector
): string => {
  if (loadStopProgress?.status === LoadStopProgressStatus.AT_FACILITY) {
    return 'Arrived'
  }

  const isEnRoute = loadStopProgress?.status === LoadStopProgressStatus.EN_ROUTE

  if (isEnRoute && trackingStatus === TrackingStatus.ERROR) {
    return 'Tracking unavailable'
  }

  if (isEnRoute && trackingStatus === TrackingStatus.STALE_LOCATION) {
    if (isBrokerView) {
      return 'Tracking unavailable'
    }
    return `Stale (${
      latestTruckLocationVector
        ? getTimeDifferenceFromNowString(latestTruckLocationVector?.timestamp, false, true)
        : ''
    })`
  }

  if (isEnRoute && trackingStatus === TrackingStatus.NO_LOCATION) {
    if (isBrokerView) {
      return 'Tracking unavailable'
    }
    return 'No location'
  }

  if (isEnRoute && isBrokerView) {
    return 'In transit'
  }

  if (
    isBrokerView &&
    isEnRoute &&
    [TrackingStatus.STALE_LOCATION, TrackingStatus.NO_LOCATION].includes(trackingStatus)
  ) {
    return 'Tracking unavailable'
  }

  const isLate = [TrackingStatus.LATE, TrackingStatus.MAYBE_LATE].includes(trackingStatus)
  if (isEnRoute && isLate && showHosEta && loadStopProgress?.providerEta) {
    return `Late - ${getTimeDifference(
      loadStopProgress?.providerEta?.providerEta,
      loadStopProgress?.stop.dateEnd
    )}`
  }

  if (isEnRoute) {
    return _.startCase(trackingStatus.toLowerCase())
  }

  if (loadStopProgress) {
    return _.startCase(loadStopProgress.status.toLowerCase())
  }

  return ''
}

export const getLoadStopProgressIsManualCheckin = (loadStopProgress: GenericLoadStopProgress): boolean => {
  if (loadStopProgress?.status === LoadStopProgressStatus.AT_FACILITY) {
    return loadStopProgress?.arrivalStopCheckin?.trigger === 'MANUAL'
  }
  if (loadStopProgress?.status === LoadStopProgressStatus.DEPARTED) {
    return loadStopProgress?.departureStopCheckin?.trigger === 'MANUAL'
  }
  return false
}

export const isDateFromManualCheckin = (
  loadStopProgress: GenericLoadStopProgress,
  dateType: 'arrival' | 'departure'
) => {
  if (dateType === 'arrival') {
    return loadStopProgress?.arrivalStopCheckin?.trigger === 'MANUAL'
  }
  if (dateType === 'departure') {
    return loadStopProgress?.departureStopCheckin?.trigger === 'MANUAL'
  }
  return false
}

export const getTripRate = (loadTripRate: number | string, confirmedRate?: number): number => {
  if (confirmedRate) {
    return confirmedRate
  }
  return loadTripRate && loadTripRate !== '0.00' ? Number(loadTripRate) : 0
}

export const formatTripRate = (tripRate: number): string => {
  return Number(tripRate).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

export const getTripRatePerMile = (tripRate: number, tripDistance: number): string => {
  return tripRate && tripDistance ? Number(tripRate / tripDistance).toFixed(2) : '0'
}

export const getLoadStopOrigin = (job?: Job) => {
  if (!job) {
    return null
  }
  const origin = job.load.stops.find((stop) => stop.type === LoadStopType.ORIGIN) as LoadStop
  return origin || null
}

export const formatEquipments = (equipments: EquipmentType[]) => {
  return !!equipments && equipments.length > 0 ? replaceUnderscore(equipments[0].toLowerCase()) : ''
}

export const formatLoadStopDate = (date: DateTime, timezone: string) =>
  date.setZone(timezone).toLocaleString({
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    hourCycle: 'h23',
  })

export const stopAppointmentWindow = (stop: LoadStop) => {
  const localizedDateStart = formatLoadStopDate(DateTime.fromISO(stop.dateStart), stop.locationTimezone)
  const localizedDateEnd = formatLoadStopDate(DateTime.fromISO(stop.dateEnd), stop.locationTimezone)
  return localizedDateStart !== localizedDateEnd
    ? `${localizedDateStart} - ${localizedDateEnd}`
    : localizedDateStart
}
