import * as React from 'react'
import { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { HandlerOf } from 'shared/helpers/typeHelper'

import { KEYBOARD } from '../../helpers/constants'
import { bgGray, midGray } from '../../theme/colors'
import Overlay from '../Overlay/Overlay'
import { Text } from '../Text/Text'
import { TextInput } from '../TextInput/TextInput'
import { DropdownMonoselectProps, DropdownMultiselectProps, DropdownOption } from './Dropdown'
import { DropdownOpenerContainer, EmptyOption, Option, Options, OptionsBottomAction } from './Dropdown.styles'

declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

interface DropDownOpenerMonoselectProps<T> extends Omit<DropdownMonoselectProps<T>, 'children'> {}

interface DropDownOpenerMultiselectProps<T> extends Omit<DropdownMultiselectProps<T>, 'children'> {}

export type DropDownOpenerProps<T> = (
  | DropDownOpenerMonoselectProps<T>
  | DropDownOpenerMultiselectProps<T>
) & {
  children: (isOpen: boolean, setOpen: HandlerOf<boolean>) => React.ReactNode
  bottomAction?: React.ReactNode
}

function DropDownOpenerInner<T>(
  {
    className,
    options,
    isInitiallyOpen = false,
    closeOnClick = true,
    children,
    bottomAction,
    maxHeight,
    bottom,
    width,
    align,
    position,
    optionsLabel,
    optionsMargin,
    emptyOptionsLabel,
    isDisabled,
    onChange,
    onBlur,
    onToggle,
    value: valueProp,
    fontSize,
    addOptionsFilter,
    isMultiselect = false,
    isChangeOnBlurMultiselect = false,
  }: DropDownOpenerProps<T>,
  ref: any
) {
  const [optionFilterValue, setOptionFilterValue] = useState('')
  const [isOpen, setOpen] = useState(isInitiallyOpen)
  const [multiselectSelectedValues, setMultiselectSelectedValues] = useState<T[]>(
    isMultiselect || isChangeOnBlurMultiselect ? ((valueProp ?? []) as T[]) : []
  )
  const value = isMultiselect && isChangeOnBlurMultiselect ? multiselectSelectedValues : valueProp

  const wrap = useRef<HTMLDivElement>(null)

  useImperativeHandle(ref, () => ({
    openDropdown: () => setOpen(true),
    closeDropdown: () => setOpen(false),
    focus: () => wrap.current?.focus(),
  }))

  const handleToggleOptions = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (isDisabled) return
    onToggle?.(!isOpen)
    setOpen((prevState) => !prevState)
  }

  const handleOverlayClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onBlur?.()
    setOpen(false)

    if (isMultiselect && isChangeOnBlurMultiselect) {
      const multiselectValue: T[] = (value ?? []) as T[]
      const multiselectOnChange = onChange as HandlerOf<T[]> | undefined
      multiselectOnChange?.(multiselectValue)
    }
  }

  const handleOnClick = (option: DropdownOption<T>, e: React.MouseEvent) => {
    !isChangeOnBlurMultiselect && closeOnClick && setOpen(false)
    if (isMultiselect && isChangeOnBlurMultiselect) {
      e.stopPropagation()
      e.preventDefault()
      return setMultiselectSelectedValues((currValues) =>
        currValues.includes(option.value as T)
          ? currValues.filter((v) => v !== option.value)
          : currValues.concat(option.value as T)
      )
    } else if (isMultiselect) {
      const multiselectValue = (value ?? []) as T[]
      const multiselectOnChange = onChange as HandlerOf<T[]> | undefined
      option.value &&
        (multiselectValue.includes(option.value)
          ? multiselectOnChange?.(multiselectValue.filter((v) => v !== option.value))
          : multiselectOnChange?.(multiselectValue.concat(option.value)))
    } else {
      const monoselectOnChange = onChange as HandlerOf<T> | undefined
      option.value && monoselectOnChange?.(option.value as T)
    }
    option.onClick && option.onClick()
  }

  const handleKeyDown = useCallback((event) => {
    if (event.key === KEYBOARD.ESCAPE) setOpen(false)
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  function renderOption(option: DropdownOption<T>) {
    const multiselectValue = (value ?? []) as T[]
    const isSelected = isMultiselect
      ? option.value && multiselectValue && multiselectValue.includes(option.value)
      : value && option.value === value
    return (
      <Option
        key={String(option.value || option.label)}
        onClick={(e) => handleOnClick(option, e)}
        data-select-option={option.label}
        $isSelected={isSelected}
      >
        {option.dropDownLabel || option.label}
      </Option>
    )
  }

  const isOpenWithOptions = isOpen && (options.length || emptyOptionsLabel || optionsLabel || bottomAction)
  const lowercasedFilter = optionFilterValue?.toLowerCase()
  const filteredOptions =
    optionFilterValue && addOptionsFilter
      ? options?.filter((o) => {
          if (typeof o.label === 'string' && o.label.toLowerCase().includes(lowercasedFilter)) return true
          if (typeof o.value === 'string' && o.value.toLowerCase().includes(lowercasedFilter)) return true
          return false
        })
      : options

  return (
    <DropdownOpenerContainer className={className} ref={wrap}>
      <div onClick={handleToggleOptions}>{children(isOpen, setOpen)}</div>
      {isOpenWithOptions && (
        <>
          <Overlay onClick={handleOverlayClick} isTransparent={true} />
          <Options
            className="dropdown-opener-options"
            $bottom={bottom}
            $width={width}
            $align={align}
            $position={position}
            $maxHeight={maxHeight}
            $margin={optionsMargin}
            $fontSize={fontSize}
          >
            {optionsLabel ? (
              <EmptyOption>
                <Text variant="header4" fontColor={midGray}>
                  {optionsLabel}
                </Text>
              </EmptyOption>
            ) : null}
            {addOptionsFilter ? (
              <EmptyOption>
                <TextInput
                  value={optionFilterValue}
                  onChange={(e) => setOptionFilterValue(e.target.value)}
                  backgroundColor={bgGray}
                  label="Filter"
                  noMargin
                  isSmall
                />
              </EmptyOption>
            ) : null}
            {filteredOptions.length === 0 ? <EmptyOption>{emptyOptionsLabel}</EmptyOption> : null}
            {filteredOptions.map((option: DropdownOption<T>) => {
              return (
                <React.Fragment key={option.value! as string}>
                  {option.renderer ? option.renderer(option, () => setOpen(false)) : renderOption(option)}
                </React.Fragment>
              )
            })}
          </Options>
          {bottomAction && (
            <OptionsBottomAction $maxHeight={maxHeight} $width={width} $align={align}>
              {bottomAction}
            </OptionsBottomAction>
          )}
        </>
      )}
    </DropdownOpenerContainer>
  )
}

export const DropDownOpener = React.forwardRef(DropDownOpenerInner)
