import { Fragment } from 'react'

import { OnboardingChecklistName } from '../../models/onboarding'
import { StyledCard, StyledSubTasks } from './TaskList.styles'
import { TaskListContent } from './TaskListContent'
import { TaskListSubTask } from './TaskListSubTask'

export enum TaskStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  PREREQUISITES_NOT_MET = 'PREREQUISITES_NOT_MET',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
}

export interface Task {
  key?: string
  name: OnboardingChecklistName
  title: string
  titleRenderer?: React.ReactNode
  subTitle?: string
  status: TaskStatusEnum
  statusText: string
  statusAdditionalText: React.ReactNode | React.ReactNode[]
  subTasks?: ReadonlyArray<SubTask>
  isExpanded?: boolean
  ctaText?: React.ReactNode | string | null
  onCtaClick?: () => void
  ctaRenderer?: React.ReactNode
  isDisabled?: boolean
  hasLinearOnboarding?: boolean
}

export type SubTask = Omit<Task, 'subTasks' | 'isExpanded'>

export interface TaskListProps {
  tasks: ReadonlyArray<Task>
  isReadOnly?: boolean
  hasLinearOnboarding?: boolean
}

interface TaskListContentWrapperProps {
  task: Task
  isReadOnly?: boolean
}

const transformTasksWithDisabledProperty = (tasks: ReadonlyArray<Task>, hasLinearOnboarding?: boolean) => {
  return tasks.map((task) => {
    return {
      ...task,
      isDisabled:
        task.status === TaskStatusEnum.PREREQUISITES_NOT_MET || (hasLinearOnboarding && task.isDisabled),
    }
  })
}

const TaskListContentWrapper: React.FC<TaskListContentWrapperProps> = ({ task, isReadOnly }) => {
  if (task.subTasks?.length && !task.isDisabled) {
    return (
      <Fragment key={`${task.title}-${task.statusText}-${task.key}`}>
        <TaskListContent task={task} isInsideAccordion isReadOnly={isReadOnly} />
        <StyledSubTasks>
          {task.subTasks.map((t) => (
            <TaskListSubTask
              key={`${t.title}-${t.statusText}-${t.key}`}
              subTask={t}
              isReadOnly={isReadOnly}
            />
          ))}
        </StyledSubTasks>
      </Fragment>
    )
  }

  return <TaskListContent task={task} isReadOnly={isReadOnly} key={`${task.title}-${task.statusText}`} />
}

export const TaskList: React.FC<TaskListProps> = ({ tasks, isReadOnly, hasLinearOnboarding }) => {
  // Fragment needed for storybook
  return (
    <>
      {transformTasksWithDisabledProperty(tasks, hasLinearOnboarding).map((task) => (
        <StyledCard className="tasklist-card" key={`${task.title}-${task.statusText}`}>
          <TaskListContentWrapper task={task} isReadOnly={isReadOnly} />
        </StyledCard>
      ))}
    </>
  )
}
