import {
  ErrorIconFilled,
  LockIcon,
  NotStartedIcon,
  PendingIconFilled,
  SuccessIconFilled,
} from '../Icons/Icon'
import { Task, TaskStatusEnum } from './TaskList'

export function getIconName({ status, isDisabled }: Task) {
  if (isDisabled) return LockIcon

  switch (status) {
    case TaskStatusEnum.NOT_STARTED:
      return NotStartedIcon

    case TaskStatusEnum.PREREQUISITES_NOT_MET:
      return LockIcon

    case TaskStatusEnum.COMPLETED:
      return SuccessIconFilled

    case TaskStatusEnum.ERROR:
      return ErrorIconFilled

    case TaskStatusEnum.IN_PROGRESS:
      return PendingIconFilled
  }
}
