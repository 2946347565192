import * as H from 'history'
import { generatePath } from 'react-router'
import { Button } from 'shared/components/Button/Button'
import { parseOnboardingTaskList as _parseOnboardingTaskList } from 'shared/components/Onboarding/OnboardingTasklist.util'
import { TaskStatusEnum } from 'shared/components/TaskList/TaskList'
import { getBackgroundCheckStatus } from 'shared/models/backgroundCheck'
import { consentClicked } from 'shared/models/clearinghouse'
import { BackgroundCheckVendor } from 'shared/models/onboarding'
import { Team as TeamV3 } from 'shared/models/team'
import { DriverStatus, User as UserV3 } from 'shared/models/user'

import { verifyAccount } from '../../api/stripeApi'
import { isLeaseToOwn } from '../../hooks/useProductSubscription'
import { Team } from '../../models/models'
import { OnboardingChecklist, OnboardingChecklistName, OnboardingSubChecklist } from '../../models/onboarding'
import { PlaidAccountStatus } from '../../models/plaid'
import { ProductSubscriptionBundleName, ProductSubscriptionName } from '../../models/productSubscription'
import { User } from '../../models/user'
import {
  CONTRACTOR_PAYMENTS_ADD_CONTRACTORS_PATH,
  CONTRACTOR_PAYMENTS_PAYROLL_BANK_ACCOUNT_PATH,
  CONTRACTOR_PERSONAL_DEBIT_CARD_PATH,
  CONTRACTOR_PERSONAL_DIRECT_DEPOSIT_PATH,
  ONBOARDING_ADD_DRIVER_PATH,
  ONBOARDING_BANK_ACCOUNT_PATH,
  ONBOARDING_BROKER_SETUP_PATH,
  ONBOARDING_CLEARINGHOUSE_PATH,
  ONBOARDING_CONFIRM_CT_CASH_PATH,
  ONBOARDING_DRIVER_APPLICATION,
  ONBOARDING_DRIVING_HISTORY_STATUS_PATH,
  ONBOARDING_DRUG_SCREEN_PATH,
  ONBOARDING_ELD_SETUP_PATH,
  ONBOARDING_EMPLOYMENT_HISTORY,
  ONBOARDING_EMPLOYMENT_HISTORY_ADD,
  ONBOARDING_EQUIPMENT_ADD_TRAILER_PATH,
  ONBOARDING_EQUIPMENT_ADD_TRUCK_PATH,
  ONBOARDING_EQUIPMENT_EDIT_TRAILER_PATH,
  ONBOARDING_EQUIPMENT_EDIT_TRUCK_PATH,
  ONBOARDING_EQUIPMENT_TRAILER_DOCUMENTS_PATH,
  ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH,
  ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH_DOT_INSPECTION,
  ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH_PLATES,
  ONBOARDING_FLEET_APPLICATION_PATH,
  ONBOARDING_FLEET_DETAILS_PATH,
  ONBOARDING_FLEX_SUBSCRIPTION_PATH,
  ONBOARDING_FUEL_CARD_PATH,
  ONBOARDING_INSURANCE_DOCUMENTS,
  ONBOARDING_MAINTENANCE_SET_ASIDE,
  ONBOARDING_SIGN_LEASE_AGREEMENT_PATH,
  ONBOARDING_TASK_LIST_PATH,
  ONBOARDING_TAX_INFO_PATH,
  ONBOARDING_TRUCK_LEASE_APPLICATION,
} from '../../Routes'
import { ContractorPaymentsBusinessOnboardingButton } from '../ContractorPayments/Onboarding/ContractorPaymentsBusinessOnboardingButton'
import { ContractorPaymentsPersonalOnboardingButton } from '../ContractorPayments/Onboarding/ContractorPaymentsPersonalOnboardingButton'
import { CheckrOnboardingButton } from './CheckrOnboardingButton'
import { getOnboardingEquipmentAddCta } from './Equipment/components/OnboardingEquipmentAddCta'
import { OnboardingCallButton } from './OnboardingCallButton'
import { OnboardingInsuranceHelpButton } from './OnboardingInsuranceHelpButton'
import { OnboardingWebinarButton } from './OnboardingWebinarButton'

// TODO: @nyu GRO-322 remove this once we have disabled statuses and task order in the BE
export const LINEAR_TASK_LIST_NEXT_TASK_PATH_MAP = {
  [ONBOARDING_DRIVER_APPLICATION]: ONBOARDING_FLEET_DETAILS_PATH,
  [ONBOARDING_FLEET_DETAILS_PATH]: ONBOARDING_EQUIPMENT_ADD_TRUCK_PATH,
  [ONBOARDING_EQUIPMENT_ADD_TRUCK_PATH]: ONBOARDING_EQUIPMENT_ADD_TRAILER_PATH,
  [ONBOARDING_EQUIPMENT_ADD_TRAILER_PATH]: ONBOARDING_DRIVING_HISTORY_STATUS_PATH,
  [ONBOARDING_DRIVING_HISTORY_STATUS_PATH]: ONBOARDING_SIGN_LEASE_AGREEMENT_PATH,
  [ONBOARDING_SIGN_LEASE_AGREEMENT_PATH]: ONBOARDING_CLEARINGHOUSE_PATH,
  [ONBOARDING_CLEARINGHOUSE_PATH]: ONBOARDING_DRUG_SCREEN_PATH,
  [ONBOARDING_DRUG_SCREEN_PATH]: ONBOARDING_TASK_LIST_PATH,
}

export function parseOnboardingTaskList(
  onboardingChecklist: ReadonlyArray<OnboardingChecklist>,
  user: UserV3,
  team: TeamV3,
  history: H.History
) {
  return _parseOnboardingTaskList(
    onboardingChecklist,
    user,
    team,
    (task) => getCtaText(task),
    (task) => getCta(task, history, user, team),
    (task) => getCtaRenderer(task, history, user, team)
  )
}

function getCta(
  task: OnboardingChecklist | OnboardingSubChecklist,
  history: H.History,
  user: UserV3,
  team: TeamV3
) {
  switch (task.name) {
    case OnboardingChecklistName.SIGN_AGREEMENT:
      if (user.featureFlags?.publicInAppSignLeaseAgreement) {
        if (task.status === TaskStatusEnum.NOT_STARTED || task.status === TaskStatusEnum.IN_PROGRESS)
          return () => history.push(ONBOARDING_SIGN_LEASE_AGREEMENT_PATH)
        return undefined
      }
      return undefined

    case OnboardingChecklistName.DRIVER_APPLICATION: {
      if (task.status === TaskStatusEnum.COMPLETED || task.status === TaskStatusEnum.IN_PROGRESS) {
        return undefined
      }

      return () => history.push(ONBOARDING_DRIVER_APPLICATION)
    }

    case OnboardingChecklistName.EMPLOYMENT_HISTORY: {
      if (task.status === TaskStatusEnum.COMPLETED || task.status === TaskStatusEnum.IN_PROGRESS) {
        return undefined
      }

      if (user.hasEmploymentJobs) {
        return () => history.push(ONBOARDING_EMPLOYMENT_HISTORY)
      }

      return () => history.push(ONBOARDING_EMPLOYMENT_HISTORY_ADD)
    }

    case OnboardingChecklistName.TAX_INFO:
      return () => history.push(ONBOARDING_TAX_INFO_PATH)

    case OnboardingChecklistName.ADD_INDIVIDUAL_EQUIPMENT: {
      if (task.status === TaskStatusEnum.COMPLETED) return undefined
      if (task?.context?.truck?.id)
        return () =>
          history.push(
            generatePath(ONBOARDING_EQUIPMENT_EDIT_TRUCK_PATH, {
              equipmentId: task?.context?.truck?.id!,
            })
          )
      else
        return () =>
          history.push(
            generatePath(ONBOARDING_EQUIPMENT_EDIT_TRAILER_PATH, {
              equipmentId: task?.context?.trailer?.id!,
            })
          )
    }

    case OnboardingChecklistName.ADD_INDIVIDUAL_EQUIPMENT_DOCUMENTS: {
      if (task.status === TaskStatusEnum.COMPLETED || task.status === TaskStatusEnum.IN_PROGRESS)
        return undefined
      if (task?.context?.truck?.id)
        return () =>
          history.push(
            generatePath(ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH, {
              equipmentId: task?.context?.truck?.id!,
            })
          )
      else
        return () =>
          history.push(
            generatePath(ONBOARDING_EQUIPMENT_TRAILER_DOCUMENTS_PATH, {
              equipmentId: task?.context?.trailer?.id!,
            })
          )
    }

    case OnboardingChecklistName.PLATES:
      if (task.status !== TaskStatusEnum.NOT_STARTED && user.featureFlags?.publicPlatesProgramOnboarding)
        return undefined
      return () =>
        history.push(
          generatePath(ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH_PLATES, {
            equipmentId: task?.context?.truck?.id!,
          })
        )

    case OnboardingChecklistName.DOT_INSPECTION:
      return () =>
        history.push(
          generatePath(ONBOARDING_EQUIPMENT_TRUCK_DOCUMENTS_PATH_DOT_INSPECTION, {
            equipmentId: task?.context?.truck?.id!,
          })
        )

    case OnboardingChecklistName.TRUCK_LEASE_APPLICATION:
      if (task.status === TaskStatusEnum.COMPLETED) return undefined
      return () => history.push(ONBOARDING_TRUCK_LEASE_APPLICATION)

    case OnboardingChecklistName.FLEET_APPLICATION:
      return () => history.push(ONBOARDING_FLEET_APPLICATION_PATH)

    case OnboardingChecklistName.FLEET_DETAILS:
      if (task.status === TaskStatusEnum.COMPLETED || task.status === TaskStatusEnum.IN_PROGRESS)
        return undefined
      return () => history.push(ONBOARDING_FLEET_DETAILS_PATH)

    case OnboardingChecklistName.ADD_DRIVERS:
      if (!user.permissions?.addTeammate.enabled && user.isTeamDriver) return undefined
      return () => history.push(ONBOARDING_ADD_DRIVER_PATH)

    case OnboardingChecklistName.ADD_INDIVIDUAL_DRIVER: {
      if (team.productSubscriptionName !== ProductSubscriptionBundleName.PREMIUM) {
        return undefined
      }
      if (!user.isTeamAdmin) {
        return undefined
      }
      if (task.context?.user?.isTeamAdmin) {
        return undefined
      }
      return () => history.push(`/onboarding/tasklist/${task.context?.user?.id}/`)
    }

    case OnboardingChecklistName.BROKER_SETUP:
      if (task.status === TaskStatusEnum.COMPLETED) return undefined // No cta when all is linked
      return () => history.push(ONBOARDING_BROKER_SETUP_PATH)

    case OnboardingChecklistName.ELD_SETUP:
      if (task.status === TaskStatusEnum.COMPLETED) {
        return undefined
      }
      return () => history.push(ONBOARDING_ELD_SETUP_PATH)

    case OnboardingChecklistName.ADD_INDIVIDUAL_BROKER:
      return undefined // Explicit no cta, only list of the added brokers

    case OnboardingChecklistName.BANK_ACCOUNT_INFO:
      return () => history.push(ONBOARDING_BANK_ACCOUNT_PATH)

    case OnboardingChecklistName.CONFIRM_CT_CARD_INFO:
      if (task.status === TaskStatusEnum.NOT_STARTED)
        return () => history.push(ONBOARDING_CONFIRM_CT_CASH_PATH)
      else return undefined // Not cta once you submitted

    case OnboardingChecklistName.STRIPE_PAYMENT_PROFILE: {
      return () => {
        if (team.id)
          return verifyAccount(team.id, true).then((url: string) => {
            window.location.href = url
          })
        else return console.error("Can't verify stripe account")
      }
    }

    case OnboardingChecklistName.FLEX_STARTER_SUBSCRIPTION:
      if (task.status === TaskStatusEnum.NOT_STARTED || task.status === TaskStatusEnum.ERROR)
        return () => history.push(ONBOARDING_FLEX_SUBSCRIPTION_PATH)
      return undefined

    case OnboardingChecklistName.INSURANCE_INFORMATION:
      if (task.status === TaskStatusEnum.COMPLETED || task.status === TaskStatusEnum.IN_PROGRESS) {
        return undefined
      }

      return () => history.push(ONBOARDING_INSURANCE_DOCUMENTS)

    case OnboardingChecklistName.MAINTENANCE_SET_ASIDE:
      return () => history.push(ONBOARDING_MAINTENANCE_SET_ASIDE)

    case OnboardingChecklistName.CONTRACTOR_PAYMENTS_PROFILE:
      return undefined

    case OnboardingChecklistName.PAYROLL_BANK_ACCOUNT:
      if (task.status === TaskStatusEnum.NOT_STARTED || task.status === TaskStatusEnum.ERROR)
        return () => history.push(CONTRACTOR_PAYMENTS_PAYROLL_BANK_ACCOUNT_PATH)
      return undefined

    case OnboardingChecklistName.ADD_CONTRACTOR:
      return () => history.push(CONTRACTOR_PAYMENTS_ADD_CONTRACTORS_PATH)

    case OnboardingChecklistName.CONTRACTOR_PERSONAL_PROFILE:
      return undefined

    case OnboardingChecklistName.CONTRACTOR_PERSONAL_DIRECT_DEPOSIT:
      if (task.status === TaskStatusEnum.NOT_STARTED || task.status === TaskStatusEnum.ERROR)
        return () => history.push(CONTRACTOR_PERSONAL_DIRECT_DEPOSIT_PATH)
      return undefined

    case OnboardingChecklistName.CONTRACTOR_PERSONAL_DEBIT_CARD:
      if (task.status === TaskStatusEnum.NOT_STARTED || task.status === TaskStatusEnum.ERROR)
        return () => history.push(CONTRACTOR_PERSONAL_DEBIT_CARD_PATH)
      return undefined
    case OnboardingChecklistName.FUEL_CARD:
      if (task.status === TaskStatusEnum.NOT_STARTED || task.status === TaskStatusEnum.IN_PROGRESS)
        return () => history.push(ONBOARDING_FUEL_CARD_PATH)
      else return undefined
  }
}

function getCtaText(task: OnboardingChecklist | OnboardingSubChecklist) {
  if (task.status === TaskStatusEnum.NOT_STARTED) {
    if (task.name === OnboardingChecklistName.ADD_DRIVERS) {
      return 'Add'
    }

    if (
      task.name === OnboardingChecklistName.FLEET_APPLICATION ||
      task.name === OnboardingChecklistName.FLEET_DETAILS ||
      task.name === OnboardingChecklistName.BACKGROUND_CHECK ||
      task.name === OnboardingChecklistName.TAX_INFO ||
      task.name === OnboardingChecklistName.EQUIPMENT_SETUP ||
      task.name === OnboardingChecklistName.BROKER_SETUP ||
      task.name === OnboardingChecklistName.CONFIRM_CT_CARD_INFO ||
      task.name === OnboardingChecklistName.BANK_ACCOUNT_INFO ||
      task.name === OnboardingChecklistName.FLEX_STARTER_SUBSCRIPTION ||
      task.name === OnboardingChecklistName.STRIPE_PAYMENT_PROFILE ||
      task.name === OnboardingChecklistName.EMPLOYMENT_HISTORY ||
      task.name === OnboardingChecklistName.DRIVER_APPLICATION ||
      task.name === OnboardingChecklistName.CONTRACTOR_PERSONAL_DEBIT_CARD ||
      task.name === OnboardingChecklistName.CONTRACTOR_PERSONAL_DIRECT_DEPOSIT ||
      task.name === OnboardingChecklistName.PAYROLL_BANK_ACCOUNT ||
      task.name === OnboardingChecklistName.SIGN_AGREEMENT ||
      task.name === OnboardingChecklistName.ADD_CONTRACTOR ||
      task.name === OnboardingChecklistName.MAINTENANCE_SET_ASIDE ||
      task.name === OnboardingChecklistName.FUEL_CARD ||
      task.name === OnboardingChecklistName.PLATES ||
      task.name === OnboardingChecklistName.TRUCK_LEASE_APPLICATION ||
      task.name === OnboardingChecklistName.DOT_INSPECTION
    ) {
      return 'Start'
    }

    if (
      task.name === OnboardingChecklistName.ADD_INDIVIDUAL_EQUIPMENT_DOCUMENTS ||
      task.name === OnboardingChecklistName.INSURANCE_INFORMATION
    ) {
      return 'Upload'
    }

    return 'Continue'
  }

  if (task.status === TaskStatusEnum.COMPLETED) {
    if (
      task.name === OnboardingChecklistName.TAX_INFO ||
      task.name === OnboardingChecklistName.FLEET_APPLICATION ||
      task.name === OnboardingChecklistName.FLEET_DETAILS ||
      task.name === OnboardingChecklistName.PAYROLL_BANK_ACCOUNT ||
      task.name === OnboardingChecklistName.ADD_CONTRACTOR
    ) {
      return 'See details'
    }
  }

  if (task.status === TaskStatusEnum.IN_PROGRESS) {
    if (task.name === OnboardingChecklistName.FUEL_CARD) {
      return 'Continue'
    }
    if (task.name === OnboardingChecklistName.BANK_ACCOUNT_INFO) {
      if (task.context?.plaidAccount?.verificationStatus === PlaidAccountStatus.PENDING_MANUAL_VERIFICATION) {
        return `Continue`
      }
    }
  }

  if (task.name === OnboardingChecklistName.BROKER_SETUP) {
    return 'Link more'
  }

  if (
    task.name === OnboardingChecklistName.ADD_DRIVERS ||
    task.name === OnboardingChecklistName.EQUIPMENT_SETUP
  ) {
    return 'Add more'
  }

  if (task.status === TaskStatusEnum.ERROR) {
    if (
      task.name === OnboardingChecklistName.FLEET_APPLICATION ||
      task.name === OnboardingChecklistName.ADD_INDIVIDUAL_EQUIPMENT ||
      task.name === OnboardingChecklistName.EMPLOYMENT_HISTORY ||
      task.name === OnboardingChecklistName.DRIVER_APPLICATION ||
      task.name === OnboardingChecklistName.ADD_INDIVIDUAL_EQUIPMENT_DOCUMENTS
    ) {
      return 'Continue'
    }

    if (task.name === OnboardingChecklistName.FLEX_STARTER_SUBSCRIPTION) {
      return 'Start'
    }
  }

  return 'See details'
}

const getCtaRenderer = (
  task: OnboardingChecklist | OnboardingSubChecklist,
  history: H.History,
  user: UserV3,
  team: TeamV3
) => {
  if (task.name === OnboardingChecklistName.EQUIPMENT_SETUP) {
    return getOnboardingEquipmentAddCta(task as OnboardingChecklist, history, team)
  }

  if (task.name === OnboardingChecklistName.BACKGROUND_CHECK) {
    if (task.context?.backgroundCheckVendor === BackgroundCheckVendor.CHECKR) {
      return <CheckrOnboardingButton task={task} user={user} />
    }
    if (task.status === TaskStatusEnum.NOT_STARTED || task.status === TaskStatusEnum.IN_PROGRESS) {
      const handleClick = () =>
        getBackgroundCheckStatus(user.id).then((data) => {
          if (data.invitation_url) window.open(data.invitation_url, '_blank')
          else return console.error("Can't get invitation url")
        })

      return (
        <Button size="medium" variant="secondary" onClick={handleClick}>
          Complete
        </Button>
      )
    }
  }

  if (
    task.name === OnboardingChecklistName.CLEARINGHOUSE_QUERY &&
    task.status === TaskStatusEnum.NOT_STARTED
  ) {
    const handleClick = () =>
      consentClicked().then(() => {
        window.open('https://clearinghouse.fmcsa.dot.gov/Account/Login', '_blank')
      })

    return (
      <Button size="medium" variant="secondary" onClick={handleClick}>
        Start
      </Button>
    )
  }

  if (task.name === OnboardingChecklistName.CONTRACTOR_PAYMENTS_PROFILE) {
    return <ContractorPaymentsBusinessOnboardingButton task={task} />
  }

  if (task.name === OnboardingChecklistName.CONTRACTOR_PERSONAL_PROFILE) {
    return <ContractorPaymentsPersonalOnboardingButton task={task} />
  }

  if (task.name === OnboardingChecklistName.CREDIT_APPLICATION) {
    return null
  }

  if (task.name === OnboardingChecklistName.ONBOARDING_CALL) {
    if (task.status === TaskStatusEnum.NOT_STARTED || task.status === TaskStatusEnum.IN_PROGRESS)
      return <OnboardingCallButton />
    return null
  }

  if (task.name === OnboardingChecklistName.TRUCK_SETUP) {
    if (isLeaseToOwn(team)) return null
    return <OnboardingInsuranceHelpButton />
  }

  if (task.name === OnboardingChecklistName.ONBOARDING_WEBINAR) {
    if (task.status === TaskStatusEnum.NOT_STARTED || task.status === TaskStatusEnum.ERROR)
      return <OnboardingWebinarButton task={task} />
    return null
  }
}

export function allChecklistItemsCompleted(checklist: readonly OnboardingChecklist[] | null) {
  return checklist?.every((item) => item.status === TaskStatusEnum.COMPLETED)
}

export function hasCompletedOnboarding(
  checklist: readonly OnboardingChecklist[] | null,
  user: User,
  team: Team
) {
  const allItemsCompleted = allChecklistItemsCompleted(checklist)
  if (team.product_subscription?.product_name === ProductSubscriptionName.PREMIUM) {
    return allItemsCompleted || isFleetApprovedWithActiveOrSuspendedDriver(user, team)
  }
  if (team.product_subscription?.is_credit_only) {
    return allItemsCompleted || isFleetApprovedWithCreditTaskDone(checklist, team)
  }
  if (team.product_subscription?.is_flex_starter) {
    return allItemsCompleted || team.is_approved
  }
  // No onboarding for drivers only for flex/cash
  if (!user.is_team_admin) return true
  return allItemsCompleted || isFleetApprovedWithCtCashCompletedOrMissing(checklist, team)
}

export function isFleetApprovedWithCtCashCompletedOrMissing(
  checklist: readonly OnboardingChecklist[] | null,
  team: Team
) {
  if (!team.is_approved || !checklist) return false
  const paymentTask = checklist.find((c) => c.name === OnboardingChecklistName.PAYMENT_PROFILE_SETUP)
  const ctCashTask = paymentTask?.subitems.find(
    (c) => c.name === OnboardingChecklistName.CONFIRM_CT_CARD_INFO
  )
  if (!ctCashTask) return true // only first admin has this task
  return ctCashTask?.status === TaskStatusEnum.COMPLETED
}

export function isFleetApprovedWithActiveOrSuspendedDriver(user: User, team: Team) {
  if (!team.is_approved) return false
  if (user.is_team_driver) {
    return (
      user.is_active &&
      (user.driver?.status === DriverStatus.ACTIVE ||
        user.driver?.status === DriverStatus.SUSPENDED_TEMPORARILY)
    )
  }

  return team.num_active_drivers >= 1
}

export function isFleetApprovedWithCreditTaskDone(
  checklist: readonly OnboardingChecklist[] | null,
  team: Team
) {
  if (!team.is_approved || !checklist) return false
  const paymentTask = checklist.find((c) => c.name === OnboardingChecklistName.PAYMENT_PROFILE_SETUP)
  const creditTask = paymentTask?.subitems.find((c) => c.name === OnboardingChecklistName.CREDIT_APPLICATION)
  return creditTask?.status === TaskStatusEnum.COMPLETED
}
